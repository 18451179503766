.container {
  margin-top: 84px !important;
}

.paper {
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;
  border-radius: 15px;
  text-align: center;
  padding: 31px 25px 27px 25px;
}

.alignLeft {
  text-align: left;
}

.edit {
  background: #4A0085 !important;
  color: #FFFFFF !important;
}

.edit:hover {
  background: #8e44ad !important;
}

.done {
  background: #27ae60 !important;
}

.communityAvatar {
  width: 100px !important;
  height: 100px !important;
  border: 1px solid lightgrey;
}
.communityLogo {
  width: 70px !important;
  height: 70px !important;
  border: 1px solid lightgrey;
}
.communityName {
  font-family: Roboto, sans-serif !important;
  font-size: 30px !important;
  line-height: 35px !important;
  text-align: center;
  color: #000000;
  /* margin-top: 24px !important; */
}

.label {
  font-family: Roboto, sans-serif !important;
  font-weight: bolder !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #000000;
  margin-bottom: 11px !important;
}

.input {
  background: #F2F2F2;
  border-radius: 15px !important;
}

.btn {
  text-transform: none !important;
  border-radius: 16px !important;
  width: 115.58px;
  height: 30px;
  margin: 0 15px !important;
}

.modal {
  display: inline-block;
  background: #FFFFFF;
  border-radius: 15px;
  width: 400px;
  /* height: 307px; */
  padding-bottom: 30px;
  text-align: center;
}

.export {
  margin-top: 20px !important;
  margin-left: 17px !important;
  background: #389DA4 !important;
}

.close {
  position: absolute;
  left: -170px;
  top: 20px;
}

.count {
  font-weight: bold !important;
  font-size: 60px !important;
  line-height: 70px;
  text-align: center;
  color: #230069;
}

.eye {
  background: #4A0085 !important;
  color: #FFFFFF !important;
  margin-left: .2rem !important;
}

.more {
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #4A0085;
  text-decoration: underline;
  text-transform: none;
}