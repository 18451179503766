.title {
  font-family: Roboto, sans-serif !important;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 35px;
  align-items: center;
  color: #320064;
}

.search {
  background: #E2E2E2;
  border-radius: 16px !important;
  width: 350px;
  height: 30px;
}

.btn {
  width: 115px;
  height: 30px;
  border-radius: 16px !important;
  text-transform: none !important;
}

.add {
  background: #4A0085 !important;
}

.btnLabel {
  font-family: Roboto, sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-decoration: none;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}

.export {
  margin-left: 17px !important;
  background: #389DA4 !important;
}

.delete {
  margin-right: 17px !important;
  background: #DB093B !important;
}

.resetEnergyModule {
  margin-right: 17px !important;
  background: #007B74 !important;
}

.split {
  margin-right: 17px !important;
  background: #4A0085 !important;
}
.communityExit {
  margin-right: 17px !important;
  background: #ff5b5b !important;
}
.tableHeader {
  border-bottom: 2px solid #000000 !important;
}

.right {
  text-align: right;
}

.back {
  background: #4A0085 !important;
  color: #FFFFFF !important;
}
