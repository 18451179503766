.title {
  font-family: Roboto, sans-serif !important;
  font-weight: bold !important;
  font-size: 18px;
  line-height: 47px;
  align-items: center;
  color: #4A0085;
}
.welcomeRocket {
  width: 150%;
}
.welcomeProfiles {
  width: 100%;
}