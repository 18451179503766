body {
  margin: 0;
}

.header {
  background: #320064;
  max-height: 50px;
}

.home {
  display: flex;
}

.logo {
  margin: 11px;
  width: 30px;
  resize: 'contain';
}

.welcomeLogo {
  width: 50%;
}

.adminTitle {
  font-family: Roboto, sans-serif !important;
  font-weight: bold;
  font-size: 66px;
  line-height: 77px;
  display: flex;
  color: #000000;
  margin: 10;
}

.adminSubtitle {
  max-width: 443px;
  font-family: Roboto, sans-serif !important;
  font-weight: normal !important;
  font-size: 22px;
  line-height: 172.68%;
  display: flex;
  text-align: left;
  margin: 0 0 20px 0;
  color: #000000;
}

.title {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 48.4px;
  line-height: 73px;
  align-items: center;
  text-align: center;
  color: #3F3D56; 
}

.copyright {
  font-family: 'Poppins', sans-serif !important;
  font-weight: normal;
  font-size: 22px !important;
  line-height: 172.68%;
  text-align: center;
  color: #000000;
}

.form {
  max-width: 491px;
}

.fields {
  background: #EAEAEA;
  border-radius: 15px;
  border: none;
  margin-bottom: 26px;
}

.loginBtn {
  text-transform: none !important;
  background: #4A0085 !important;
  border-radius: 30.5px !important;
  width: 246px;
  height: 61px;
  font-family: Roboto, sans-serif !important ;
  font-weight: bolder !important;
  font-size: 27.7521px !important;
  line-height: 33px;
  align-items: center;
  text-align: center;
  color: #FFFFFF !important;
}

.forgotLink {
  font-family: Roboto, sans-serif !important;
  text-transform: none;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 172.68%;
  color: #7A2FFF;
}

.footer {
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 26px;
}

@media screen and (max-width: 768px) {
  .rightSide {
    margin: 10px !important;
  }

  .adminTitle {
    font-size: 30px;
  }

  .adminSubtitle {
    font-size: 15px;
    margin: 0 0 10px 0;
  }

  .fields {
    margin-bottom: 15px;
  }

  .copyright {
    font-size: 15px !important;
  }
}