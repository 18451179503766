.badge {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  background: #2D0078 !important;
  font-weight: bolder !important;
  font-size: 14px !important;
  line-height: 16px;
  color: #FFFFFF;
}

.skill {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  padding: 11px;
}

.label {
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center;
  color: #2D0078 !important;
  margin-top: 9px !important;
}