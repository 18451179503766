.sidebar {
  background: #250049 !important;
  width: 300px;
}

.logo {
  text-align: center;
  padding-top: 22px;
}

.logoText {
  font-family: Roboto, sans-serif !important;
  font-weight: bold !important;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #FFFFFF;
}

.logout {
  display: flex;
  flex-direction: column-reverse;
  height: 80%;
}

.logoutBtn {
  text-transform: none !important;
}

.menuIcon {
  color: #FFFFFF !important;
}

.iconSide {
  color: #FFFFFF !important;
  width: 50%,
}

.menuText {
  font-family: Roboto, sans-serif !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 20px !important;
  text-decoration: none !important;
  color: #FFFFFF;
}

.selected {
  background: #4D0099 !important;
}