.cards {
  width: 300px;
  height: 150px;
  background: #4F009D;
  border-radius: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: "center";
}

.count {
  font-family: Roboto, sans-serif !important;
  font-weight: bold !important;
  font-size: 40px !important;
  line-height: 70px;
  align-items: center;
  text-align: center;
  color: #46008C;
}

.text {
  font-family: Roboto, sans-serif !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 35px;
  align-items: center;
  text-align: center;
  color: #000000;
}