.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: inline-block;
  background: #FFFFFF;
  border-radius: 15px;
  width: 400px;
  height: 230px;
  text-align: center;
}

.container h4 {
  padding-top: 51px;
}

.btn {
  text-transform: none !important;
  border-radius: 16px !important;
  width: 115.58px;
  height: 30px;
  margin: 0 15px !important;
}

.cancel {
  background-color: #FFFFFF !important;
  border: 2px solid #4A0085 !important;
  box-sizing: border-box;
}

.split {
  background: #4A0085 !important;
  color: #FFFFFF !important;
}