.header {
  border-bottom: 2px solid #000000 !important;
}

.specialBody {
  background: #66a99a !important;
  background-color: #66a99a !important;
}

.loading {
  align-content: center;
  justify-content: center;
  text-align: center;
}