.container {
  margin-top: 84px !important;
}

.title {
  font-family: Roboto, sans-serif !important;
  font-weight: bold !important;
  font-size: 30px !important;
  line-height: 35px;
  align-items: center;
  color: #000000;
}

.search {
  background: #E2E2E2;
  border-radius: 16px !important;
  width: 350px;
  height: 30px;
}

.btn {
  width: 115px;
  height: 30px;
  border-radius: 16px !important;
}

.add {
  background: #4A0085 !important;
}

.right {
  text-align: right;
}

.btnLabel {
  font-family: Roboto, sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}

.export {
  margin-left: 17px !important;
  background: #389DA4 !important;
}

.tableHeader {
  border-bottom: 2px solid #000000 !important;
}