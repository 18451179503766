.container {
  margin-top: 84px !important;
}

/* .title {
  font-family: Roboto, sans-serif !important;
  font-weight: bold !important;
  font-size: 30px !important;
  line-height: 35px;
  align-items: center;
  color: #000000;
} */

/* .search {
  background: #E2E2E2;
  border-radius: 16px !important;
  width: 350px;
  height: 30px;
} */

.btn {
  width: 115px;
  height: 30px;
  border-radius: 16px !important;
  text-transform: none !important;
}

.btnLabel {
  font-family: Roboto, sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}

.create {
  margin-left: 17px !important;
  background: #389DA4 !important;
}

.modal {
  display: inline-block;
  background: #FFFFFF;
  border-radius: 15px;
  width: 450px;
  padding-bottom: 30px;
  text-align: center;
}

.close {
  float: left;
  left: 15px;
  top: 15px;
}

.paper {
  background-color: #FFFFFF;
  border: 2px solid #000000;
  padding: 8px;
}

.avatar {
  margin-top: 31px;
  border: 1px solid lightgray;
  width: 100px !important;
  height: 100px !important;
}

.edit {
  background: #4A0085 !important;
  color: #FFFFFF !important;
}

.edit:hover {
  background: #8e44ad !important;
}

.fields {
  background: #F2F2F2;
  border-radius: 15px !important;
}
.fields2 {
  background: #F2F2F2;
  border-radius: 15px !important;
}