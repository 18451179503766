.container {
  margin-top: 84px;
}

.modal {
  display: inline-block;
  background: #FFFFFF;
  border-radius: 20px;
  width: 380px;
  min-height: 322px;
  text-align: center;
  color: #320064 !important;
}

.close {
  position: absolute;
  left: -155px;
  top: 20px;
}

.modal h4 {
  font-size: 25px !important;
  line-height: 29px !important;
  text-align: center !important;
}

.message {
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: left;
}

.paper {
  border: 2px solid #2D0078;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 14px;
  min-height: 178px;
  margin: 12px 27px 44px 27px;
}